import { BASE_URL, get, usePaginatedFetch } from 'utils/sdk';
import { OrderStatus } from './types/order';
import { Currency } from './types/store';
import _ from 'lodash';
import { formatGetParams } from 'utils/utilsV2';

export interface IOrder {
  id: number;
  date_created: string;
  products: [
    {
      id: number;
      product_name: string;
      quantity: number;
      delivery_date: string;
      price: string;
      currency: Currency;
    },
  ];
  store: {
    id: number;
    store_name: string;
  };
  order_number: string;
  status: OrderStatus;
  total_owner_amount: string;
  grand_total: string;
  currency: Currency;
  guest_name: string;
}

export const useOrders = ({
  storeId,
  status,
  startDate,
  endDate,
}: {
  storeId?: number;
  status?: OrderStatus;
  startDate?: string;
  endDate?: string;
}) => {
  let filters;

  if (!_.isUndefined(status)) {
    filters = { status };
  }
  if (!_.isUndefined(startDate)) {
    filters = { ...filters, start_date: startDate };
  }
  if (!_.isUndefined(endDate)) {
    filters = { ...filters, end_date: endDate };
  }

  if (!_.isUndefined(storeId)) {
    filters = { ...filters, store_id: storeId };
  }

  return usePaginatedFetch<{ results: Array<IOrder> }>({
    url: `services/order/list/`,
    params: { limit: 10, ...filters },
  });
};

export const exportOrders = ({
  storeId,
  status,
  startDate,
  endDate,
}: {
  storeId?: number;
  status?: OrderStatus;
  startDate?: string;
  endDate?: string;
}) => {
  let filters;

  if (!_.isUndefined(status)) {
    filters = { status };
  }
  if (!_.isUndefined(startDate)) {
    filters = { ...filters, start_date: startDate };
  }
  if (!_.isUndefined(endDate)) {
    filters = { ...filters, end_date: endDate };
  }

  if (!_.isUndefined(storeId)) {
    filters = { ...filters, store_id: storeId };
  }

  return get<
    Array<{
      guest_name: string;
      date_created: string;
      order_number: string;
      total_amount: string;
      total_owner_amount: string;
    }>
  >(
    `${BASE_URL}services/order/export-orders/${formatGetParams({
      ...filters,
    })}`,
  );
};

export const exportOrderProducts = ({
  storeId,
  status,
  startDate,
  endDate,
}: {
  storeId?: number;
  status?: OrderStatus;
  startDate?: string;
  endDate?: string;
}) => {
  let filters;

  if (!_.isUndefined(status)) {
    filters = { status };
  }
  if (!_.isUndefined(startDate)) {
    filters = { ...filters, start_date: startDate };
  }
  if (!_.isUndefined(endDate)) {
    filters = { ...filters, end_date: endDate };
  }

  if (!_.isUndefined(storeId)) {
    filters = { ...filters, store_id: storeId };
  }

  return get<
    Array<{
      guest_name: string;
      product_name: string;
      date_created: string;
      total_amount: string;
      your_profit: string;
      order_number: string;
    }>
  >(
    `${BASE_URL}services/order/export-orderproducts/${formatGetParams({
      ...filters,
    })}`,
  );
};
